import { ChevronLeft } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import "./index.scss";
import { Link, useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import scrollToTop from "../../modules/scrollToTop";

import ClassificationOfAnomaliesDialog from "./ClassificationOfAnomaliesDialog";
import { data } from "./data";

export const ClassificationOfAnomalies = () => {
  const { t: _t } = useTranslation();
  const { goBack } = useHistory();

  const [currentAnomaly, setCurrentAnomaly] = useState(1);
  const [openedDialog, setOpenedDialog] = useState(false);

  const toggleDialog = (id) => {
    setCurrentAnomaly(id);
    setOpenedDialog((prevState) => !prevState);
  };

  const list = data(_t);

  useEffect(() => {
    scrollToTop(600);
  }, []);

  return (
    <>
      {openedDialog && (
        <ClassificationOfAnomaliesDialog
          data={list.find((el) => el.id === currentAnomaly)}
          isShow={openedDialog}
          toggle={toggleDialog}
        />
      )}
      <section className="classification">
        <div className="classification__container">
          <Link to={ROUTES.home} className="classification__logo">
            <img src={require("../../assets/img/logo_dark.png")} alt="" />
          </Link>
          <div className="classification__back" onClick={goBack}>
            <ChevronLeft className="icon" />
            <span>{_t("Назад")}</span>
          </div>
          <h1>{_t("Классификация аномалий прикуса TranspaDent")}</h1>
          <p className="classification__subtitle">
            {_t("На странице классификация аномалий представлены различные нарушения прикуса, успешно исправить которые, смогут элайнеры TranspaDent. Представленные ниже 3D модели зубов наглядно демонстрируют, как при помощи продукции нашей компании выполняется выравнивание зубов.Нажав «узнать больше» на любом из типов представленных аномалий, перед пользователем появляется 3D модель челюсти с выбранным типом патологии зубов. Передвигая расположенный ниже ползунок, пользователь самостоятельно сможет увидеть выравнивание прикуса от первого до последнего этапа лечения.")}
          </p>
          <p className="classification__subtitle">{_t("Также следует обратить внимание на то, что есть некоторые нарушения прикуса, которые не лечатся системами элайнеров TranspaDent.")}</p>
          <p className="classification__subtitle">{_t("А именно:")}</p>
          <ul>
            <li>{_t("заболевание ВНЧС и высокая стираемость зубов;")}</li>
            <li>{_t("дистальный перекрёстный прикус;")}</li>
            <li>{_t("скелетный III класс по Энглю.")}</li>
          </ul>
          <div className="classification__list">
            {list.map(({ id, pic, title, necData, clarification }) => (
              <div className="classification__item" key={id}>
                <div className="classification__main">
                  <div className="classification__pic" onClick={() => toggleDialog(id)}>
                    <img src={pic} alt={title} />
                  </div>
                  <h6>{title}</h6>
                </div>
                <div className="classification__details">
                  <p>{_t("Необходимые исходные данные:")}</p>
                  <div className="classification__data">
                    {necData.map((el) => (
                      <div className="classification__innerPic" title={el.title} key={el.title}>
                        <img src={el.pic} alt={el.title} />
                        <p>{el.title}</p>
                      </div>
                    ))}
                  </div>
                  {clarification && (
                    <>
                      <p style={{ fontWeight: 700 }}>{_t("Классификация:")}</p>
                      {clarification.map((el) => (
                        <p className="classification__clarification" key={el}>
                          {el}
                        </p>
                      ))}
                    </>
                  )}
                  <Button variant="contained" color="primary" fullWidth onClick={() => toggleDialog(id)}>
                    {_t("Узнать больше")}
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
