import React from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const Recommendations = () => {
  const { t: _t } = useTranslation();
  return (
    <>
      <p>
        {_t(
          "После извлечения элайнеров из упаковки их нужно прополоскать в прохладной воде. Следует так же вымыть руки с мылом перед началом использования. Для получения желаемого результата капы следует носить элайнеры 20–22 часов в сутки ежедневно на протяжении всего периода лечения.",
        )}
      </p>
      <p>
        {_t(
          "Каждый элайнер надевается поочерёдно. Для этого нужно надеть капу на передние зубы, а затем слегка надавить на нее в области жевательных зубов. Признаком правильной фиксации элайнеров послужит лёгкий щелчок. Попытки одеть элайнеры сжатием челюстей могут привести к их повреждению.",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/6_recommendations/1.png")} effect="blur" alt="" />

      <p>
        {_t(
          "Снимать элайнеры нужно осторожно, начиная с боковых зубов и последовательно по всей передней поверхности устройства. Применение сторонних предметов для снятия может повредить элайнеры. Перед повторным надеванием кап их нужно промыть.",
        )}
      </p>
      <LazyLoadImage src={require("../../assets/6_recommendations/2.png")} effect="blur" alt="" />

      <p>
        {_t(
          "Хранить капы необходимо в специальном контейнере, предотвращающем их порчу или потерю. Уход и чистка элайнеров осуществляется минимум 2 раза в день с помощью зубной пасты и мягкой щётки. Употребление пищи, распитие горячих и сильно красящих напитков и курение в элайнерах нежелательно, поскольку это может привести к изменению их цвета либо повреждению.",
        )}
      </p>
    </>
  );
};
