export const agentsList = (_t) => [
  {
    id: 1,
    location: _t("Харьков"),
    name: _t("Компания Ortho Max"),
    tel: "+38 067 632 49 52",
  },
  {
    id: 2,
    location: _t("Днепр, Запорожье"),
    name: _t("Компания Ortho Liberty"),
    tel: "+38 067 632 51 04",
  },
  {
    id: 4,
    location: _t("Все области Украины"),
    tel: "+38 099 318 30 75",
  },
];
