import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router";
import ForDoctorsPage from "../../components/@ForDoctorsPage";
import MainPage from "../../components/@MainPage";
import ClassificationOfAnomalies from "../../components/#ClassificationOfAnomalies";
import ContactsDialog from "../../components/ContactsDialog";
import Footer from "../../components/Footer";
import { ROUTES } from "../../constants/routes";

export const HomeLayout = () => {
  const contacts = useSelector(({ contacts }) => contacts);
  return (
    <div className="homeLayout">
      {contacts.isShow && <ContactsDialog />}
      <Switch>
        <Route exact path={ROUTES.home}>
          <MainPage />
        </Route>

        <Route exact path={ROUTES.forDoctors}>
          <ForDoctorsPage />
        </Route>

        <Route exact path={ROUTES.classificationOfAnomalies}>
          <ClassificationOfAnomalies />
        </Route>
      </Switch>
      <Footer />
    </div>
  );
};
