export const slides = [
  { src: require("./slides/1.jpg") },
  { src: require("./slides/2.jpg") },
  { src: require("./slides/3.jpg") },
  { src: require("./slides/4.jpg") },
  { src: require("./slides/5.jpg") },
  { src: require("./slides/6.jpg") },
  { src: require("./slides/7.jpg") },
  { src: require("./slides/8.jpg") },
  { src: require("./slides/9.jpg") },
  { src: require("./slides/10.jpg") },
  { src: require("./slides/11.jpg") },
  { src: require("./slides/12.jpg") },
  { src: require("./slides/13.jpg") },
  { src: require("./slides/14.jpg") },
  { src: require("./slides/15.jpg") },
  { src: require("./slides/16.jpg") },
  { src: require("./slides/17.jpg") },
  { src: require("./slides/18.jpg") },
  { src: require("./slides/19.jpg") },
  { src: require("./slides/20.jpg") },
  { src: require("./slides/21.jpg") },
  { src: require("./slides/22.jpg") },
  { src: require("./slides/23.jpg") },
];
