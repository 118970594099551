import classification1 from "./assets/classification1.jpg";
import classification2 from "./assets/classification2.jpg";
import classification3 from "./assets/classification3.jpg";
import classification4 from "./assets/classification4.jpg";
import classification5 from "./assets/classification5.jpg";
import classification6 from "./assets/classification6.jpg";
import classification7 from "./assets/classification7.jpg";
import classification8 from "./assets/classification8.jpg";
import classification9 from "./assets/classification9.jpg";
import classification10 from "./assets/classification10.jpg";
import classification11 from "./assets/classification11.jpg";
import classification12 from "./assets/classification12.jpg";
import classification13 from "./assets/classification13.jpg";
import classification14 from "./assets/classification14.jpg";
import classification15 from "./assets/classification15.jpg";
import classification16 from "./assets/classification16.jpg";
import classification17 from "./assets/classification17.jpg";
import classification18 from "./assets/classification18.jpg";
import classification19 from "./assets/classification19.jpg";
import classification20 from "./assets/classification20.jpg";

import AnglesClassIIDivisionI1 from "./assets/Angle's Class II Division I/Angle's Class II Division I1.png";
import AnglesClassIIDivisionI2 from "./assets/Angle's Class II Division I/Angle's Class II Division I2.png";
import AnglesClassIIDivisionI3 from "./assets/Angle's Class II Division I/Angle's Class II Division I3.png";
import AnglesClassIIDivisionI4 from "./assets/Angle's Class II Division I/Angle's Class II Division I4.png";
import AnglesClassIIDivisionI5 from "./assets/Angle's Class II Division I/Angle's Class II Division I5.png";
import AnglesClassIIDivisionI6 from "./assets/Angle's Class II Division I/Angle's Class II Division I6.png";
import AnglesClassIIDivisionI7 from "./assets/Angle's Class II Division I/Angle's Class II Division I7.png";
import AnglesClassIIDivisionI8 from "./assets/Angle's Class II Division I/Angle's Class II Division I8.png";
import AnglesClassIIDivisionI9 from "./assets/Angle's Class II Division I/Angle's Class II Division I9.png";
import AnglesClassIIDivisionI10 from "./assets/Angle's Class II Division I/Angle's Class II Division I10.png";
import AnglesClassIIDivisionI11 from "./assets/Angle's Class II Division I/Angle's Class II Division I11.png";
import AnglesClassIIDivisionI12 from "./assets/Angle's Class II Division I/Angle's Class II Division I12.png";
import AnglesClassIIDivisionI13 from "./assets/Angle's Class II Division I/Angle's Class II Division I13.png";
import AnglesClassIIDivisionI14 from "./assets/Angle's Class II Division I/Angle's Class II Division I14.png";
import AnglesClassIIDivisionI15 from "./assets/Angle's Class II Division I/Angle's Class II Division I15.png";
import AnglesClassIIDivisionI16 from "./assets/Angle's Class II Division I/Angle's Class II Division I16.png";
import AnglesClassIIDivisionI17 from "./assets/Angle's Class II Division I/Angle's Class II Division I17.png";
import AnglesClassIIDivisionI18 from "./assets/Angle's Class II Division I/Angle's Class II Division I18.png";
import AnglesClassIIDivisionI19 from "./assets/Angle's Class II Division I/Angle's Class II Division I19.png";
import AnglesClassIIDivisionI20 from "./assets/Angle's Class II Division I/Angle's Class II Division I20.png";
import AnglesClassIIDivisionII1 from "./assets/Angle's Class II Division II/Angle's Class II Division II1.png";
import AnglesClassIIDivisionII2 from "./assets/Angle's Class II Division II/Angle's Class II Division II2.png";
import AnglesClassIIDivisionII3 from "./assets/Angle's Class II Division II/Angle's Class II Division II3.png";
import AnglesClassIIDivisionII4 from "./assets/Angle's Class II Division II/Angle's Class II Division II4.png";
import AnglesClassIIDivisionII5 from "./assets/Angle's Class II Division II/Angle's Class II Division II5.png";
import AnglesClassIIDivisionII6 from "./assets/Angle's Class II Division II/Angle's Class II Division II6.png";
import AnglesClassIIDivisionII7 from "./assets/Angle's Class II Division II/Angle's Class II Division II7.png";
import AnglesClassIIDivisionII8 from "./assets/Angle's Class II Division II/Angle's Class II Division II8.png";
import AnglesClassIIDivisionII9 from "./assets/Angle's Class II Division II/Angle's Class II Division II9.png";
import AnglesClassIIDivisionII10 from "./assets/Angle's Class II Division II/Angle's Class II Division II10.png";
import AnglesClassIIDivisionII11 from "./assets/Angle's Class II Division II/Angle's Class II Division II11.png";
import AnglesClassIIDivisionII12 from "./assets/Angle's Class II Division II/Angle's Class II Division II12.png";
import AnglesClassIIDivisionII13 from "./assets/Angle's Class II Division II/Angle's Class II Division II13.png";
import AnglesClassIIDivisionII14 from "./assets/Angle's Class II Division II/Angle's Class II Division II14.png";
import AnglesClassIIDivisionII15 from "./assets/Angle's Class II Division II/Angle's Class II Division II15.png";
import AnglesClassIIDivisionII16 from "./assets/Angle's Class II Division II/Angle's Class II Division II16.png";
import AnglesClassIIDivisionII17 from "./assets/Angle's Class II Division II/Angle's Class II Division II17.png";
import AnglesClassIIDivisionII18 from "./assets/Angle's Class II Division II/Angle's Class II Division II18.png";
import AnglesClassIIDivisionII19 from "./assets/Angle's Class II Division II/Angle's Class II Division II19.png";
import AnglesClassIIDivisionII20 from "./assets/Angle's Class II Division II/Angle's Class II Division II20.png";
import Intruded1 from "./assets/Intruded tooth/Intruded tooth 1.png";
import Intruded2 from "./assets/Intruded tooth/Intruded tooth 2.png";
import Intruded3 from "./assets/Intruded tooth/Intruded tooth 3.png";
import Intruded4 from "./assets/Intruded tooth/Intruded tooth 4.png";
import Intruded5 from "./assets/Intruded tooth/Intruded tooth 5.png";
import Intruded6 from "./assets/Intruded tooth/Intruded tooth 6.png";
import Intruded7 from "./assets/Intruded tooth/Intruded tooth 7.png";
import Intruded8 from "./assets/Intruded tooth/Intruded tooth 8.png";
import Intruded9 from "./assets/Intruded tooth/Intruded tooth 9.png";
import Intruded10 from "./assets/Intruded tooth/Intruded tooth 10.png";
import Intruded11 from "./assets/Intruded tooth/Intruded tooth 11.png";
import Intruded12 from "./assets/Intruded tooth/Intruded tooth 12.png";
import Intruded13 from "./assets/Intruded tooth/Intruded tooth 13.png";
import Crossbite1 from "./assets/Cross bite/Cross bite1.png";
import Crossbite2 from "./assets/Cross bite/Cross bite2.png";
import Crossbite3 from "./assets/Cross bite/Cross bite3.png";
import Crossbite4 from "./assets/Cross bite/Cross bite4.png";
import Crossbite5 from "./assets/Cross bite/Cross bite5.png";
import Crossbite6 from "./assets/Cross bite/Cross bite6.png";
import Crossbite7 from "./assets/Cross bite/Cross bite7.png";
import Crossbite8 from "./assets/Cross bite/Cross bite8.png";
import Crossbite9 from "./assets/Cross bite/Cross bite9.png";
import Crossbite10 from "./assets/Cross bite/Cross bite10.png";
import Crossbite11 from "./assets/Cross bite/Cross bite11.png";
import Crossbite12 from "./assets/Cross bite/Cross bite12.png";
import Crossbite13 from "./assets/Cross bite/Cross bite13.png";
import Crossbite14 from "./assets/Cross bite/Cross bite14.png";
import Crowdingofteethonbothjaws1 from "./assets/Crowding of teeth on both jaws/Crowding of teeth on both jaws1.png";
import Crowdingofteethonbothjaws2 from "./assets/Crowding of teeth on both jaws/Crowding of teeth on both jaws2.png";
import Crowdingofteethonbothjaws3 from "./assets/Crowding of teeth on both jaws/Crowding of teeth on both jaws3.png";
import Crowdingofteethonbothjaws4 from "./assets/Crowding of teeth on both jaws/Crowding of teeth on both jaws4.png";
import Crowdingofteethonbothjaws5 from "./assets/Crowding of teeth on both jaws/Crowding of teeth on both jaws5.png";
import Crowdingofteethonbothjaws6 from "./assets/Crowding of teeth on both jaws/Crowding of teeth on both jaws6.png";
import Crowdingofteethonbothjaws7 from "./assets/Crowding of teeth on both jaws/Crowding of teeth on both jaws7.png";
import Crowdingofteethonbothjaws8 from "./assets/Crowding of teeth on both jaws/Crowding of teeth on both jaws8.png";
import Crowdingofteethonbothjaws9 from "./assets/Crowding of teeth on both jaws/Crowding of teeth on both jaws9.png";
import Crowdingofteethonbothjaws10 from "./assets/Crowding of teeth on both jaws/Crowding of teeth on both jaws10.png";
import Crowdingofteethonbothjaws11 from "./assets/Crowding of teeth on both jaws/Crowding of teeth on both jaws11.png";
import Crowdingofteethonbothjaws12 from "./assets/Crowding of teeth on both jaws/Crowding of teeth on both jaws12.png";
import Crowdingofteethonbothjaws13 from "./assets/Crowding of teeth on both jaws/Crowding of teeth on both jaws13.png";
import Crowdingofteethonbothjaws14 from "./assets/Crowding of teeth on both jaws/Crowding of teeth on both jaws14.png";
import Crowdingofteethonbothjaws15 from "./assets/Crowding of teeth on both jaws/Crowding of teeth on both jaws15.png";
import Crowdingofteethononejaw1 from "./assets/Crowding of teeth on one jaw/Crowding of teeth on one jaw 1.png";
import Crowdingofteethononejaw2 from "./assets/Crowding of teeth on one jaw/Crowding of teeth on one jaw 2.png";
import Crowdingofteethononejaw3 from "./assets/Crowding of teeth on one jaw/Crowding of teeth on one jaw 3.png";
import Crowdingofteethononejaw4 from "./assets/Crowding of teeth on one jaw/Crowding of teeth on one jaw 4.png";
import Crowdingofteethononejaw5 from "./assets/Crowding of teeth on one jaw/Crowding of teeth on one jaw 5.png";
import Crowdingofteethononejaw6 from "./assets/Crowding of teeth on one jaw/Crowding of teeth on one jaw 6.png";
import Crowdingofteethononejaw7 from "./assets/Crowding of teeth on one jaw/Crowding of teeth on one jaw 7.png";
import Crowdingofteethononejaw8 from "./assets/Crowding of teeth on one jaw/Crowding of teeth on one jaw 8.png";
import Crowdingofteethononejaw9 from "./assets/Crowding of teeth on one jaw/Crowding of teeth on one jaw 9.png";
import Crowdingofteethononejaw10 from "./assets/Crowding of teeth on one jaw/Crowding of teeth on one jaw 10.png";
import Crowdingofteethononejaw11 from "./assets/Crowding of teeth on one jaw/Crowding of teeth on one jaw 11.png";
import Crowdingofteethononejaw12 from "./assets/Crowding of teeth on one jaw/Crowding of teeth on one jaw 12.png";
import Crowdingofteethononejaw13 from "./assets/Crowding of teeth on one jaw/Crowding of teeth on one jaw 13.png";
import Crowdingofteethononejaw14 from "./assets/Crowding of teeth on one jaw/Crowding of teeth on one jaw 14.png";
import Expansionofdentalarches1 from "./assets/Expansion of dental arches/Expansion of dental arches1.png";
import Expansionofdentalarches2 from "./assets/Expansion of dental arches/Expansion of dental arches2.png";
import Expansionofdentalarches3 from "./assets/Expansion of dental arches/Expansion of dental arches3.png";
import Expansionofdentalarches4 from "./assets/Expansion of dental arches/Expansion of dental arches4.png";
import Expansionofdentalarches5 from "./assets/Expansion of dental arches/Expansion of dental arches5.png";
import Expansionofdentalarches6 from "./assets/Expansion of dental arches/Expansion of dental arches6.png";
import Expansionofdentalarches7 from "./assets/Expansion of dental arches/Expansion of dental arches7.png";
import Expansionofdentalarches8 from "./assets/Expansion of dental arches/Expansion of dental arches8.png";
import Expansionofdentalarches9 from "./assets/Expansion of dental arches/Expansion of dental arches9.png";
import Expansionofdentalarches10 from "./assets/Expansion of dental arches/Expansion of dental arches10.png";
import Expansionofdentalarches11 from "./assets/Expansion of dental arches/Expansion of dental arches11.png";
import Expansionofdentalarches12 from "./assets/Expansion of dental arches/Expansion of dental arches12.png";
import Expansionofdentalarches13 from "./assets/Expansion of dental arches/Expansion of dental arches13.png";
import Expansionofdentalarches14 from "./assets/Expansion of dental arches/Expansion of dental arches14.png";
import Expansionofdentalarches15 from "./assets/Expansion of dental arches/Expansion of dental arches15.png";
import Extrudedtooth1 from "./assets/Extruded tooth/Extruded tooth1.png";
import Extrudedtooth2 from "./assets/Extruded tooth/Extruded tooth2.png";
import Extrudedtooth3 from "./assets/Extruded tooth/Extruded tooth3.png";
import Extrudedtooth4 from "./assets/Extruded tooth/Extruded tooth4.png";
import Extrudedtooth5 from "./assets/Extruded tooth/Extruded tooth5.png";
import Extrudedtooth6 from "./assets/Extruded tooth/Extruded tooth6.png";
import Extrudedtooth7 from "./assets/Extruded tooth/Extruded tooth7.png";
import Extrudedtooth8 from "./assets/Extruded tooth/Extruded tooth8.png";
import Extrudedtooth9 from "./assets/Extruded tooth/Extruded tooth9.png";
import Extrudedtooth10 from "./assets/Extruded tooth/Extruded tooth10.png";
import Extrudedtooth11 from "./assets/Extruded tooth/Extruded tooth11.png";
import Extrudedtooth12 from "./assets/Extruded tooth/Extruded tooth12.png";
import Extrudedtooth13 from "./assets/Extruded tooth/Extruded tooth13.png";
import Extrudedtooth14 from "./assets/Extruded tooth/Extruded tooth14.png";
import Extrudedtooth15 from "./assets/Extruded tooth/Extruded tooth15.png";
import Extrudedtooth16 from "./assets/Extruded tooth/Extruded tooth16.png";
import Incorrectinclinationofthetooth1 from "./assets/Incorrect inclination of the tooth/Incorrect inclination of the tooth1.png";
import Incorrectinclinationofthetooth2 from "./assets/Incorrect inclination of the tooth/Incorrect inclination of the tooth2.png";
import Incorrectinclinationofthetooth3 from "./assets/Incorrect inclination of the tooth/Incorrect inclination of the tooth3.png";
import Incorrectinclinationofthetooth4 from "./assets/Incorrect inclination of the tooth/Incorrect inclination of the tooth4.png";
import Incorrectinclinationofthetooth5 from "./assets/Incorrect inclination of the tooth/Incorrect inclination of the tooth5.png";
import Incorrectinclinationofthetooth6 from "./assets/Incorrect inclination of the tooth/Incorrect inclination of the tooth6.png";
import Incorrectinclinationofthetooth7 from "./assets/Incorrect inclination of the tooth/Incorrect inclination of the tooth7.png";
import Incorrectinclinationofthetooth8 from "./assets/Incorrect inclination of the tooth/Incorrect inclination of the tooth8.png";
import Incorrectinclinationofthetooth9 from "./assets/Incorrect inclination of the tooth/Incorrect inclination of the tooth9.png";
import Incorrectinclinationofthetooth10 from "./assets/Incorrect inclination of the tooth/Incorrect inclination of the tooth10.png";
import Incorrectinclinationofthetooth11 from "./assets/Incorrect inclination of the tooth/Incorrect inclination of the tooth11.png";
import Incorrectinclinationofthetooth12 from "./assets/Incorrect inclination of the tooth/Incorrect inclination of the tooth12.png";
import Incorrectinclinationofthetooth13 from "./assets/Incorrect inclination of the tooth/Incorrect inclination of the tooth13.png";
import OnesideAnglesClassII1 from "./assets/One side Angle's Class II/One side Angle's Class II1.png";
import OnesideAnglesClassII2 from "./assets/One side Angle's Class II/One side Angle's Class II2.png";
import OnesideAnglesClassII3 from "./assets/One side Angle's Class II/One side Angle's Class II3.png";
import OnesideAnglesClassII4 from "./assets/One side Angle's Class II/One side Angle's Class II4.png";
import OnesideAnglesClassII5 from "./assets/One side Angle's Class II/One side Angle's Class II5.png";
import OnesideAnglesClassII6 from "./assets/One side Angle's Class II/One side Angle's Class II6.png";
import OnesideAnglesClassII7 from "./assets/One side Angle's Class II/One side Angle's Class II7.png";
import OnesideAnglesClassII8 from "./assets/One side Angle's Class II/One side Angle's Class II8.png";
import OnesideAnglesClassII9 from "./assets/One side Angle's Class II/One side Angle's Class II9.png";
import OnesideAnglesClassII10 from "./assets/One side Angle's Class II/One side Angle's Class II10.png";
import OnesideAnglesClassII11 from "./assets/One side Angle's Class II/One side Angle's Class II11.png";
import OnesideAnglesClassII12 from "./assets/One side Angle's Class II/One side Angle's Class II12.png";
import OnesideAnglesClassII13 from "./assets/One side Angle's Class II/One side Angle's Class II13.png";
import OnesideAnglesClassII14 from "./assets/One side Angle's Class II/One side Angle's Class II14.png";
import OnesideAnglesClassII15 from "./assets/One side Angle's Class II/One side Angle's Class II15.png";
import OnesideAnglesClassIII1 from "./assets/One side Angle's Class III/One side Angle's Class III 1.png";
import OnesideAnglesClassIII2 from "./assets/One side Angle's Class III/One side Angle's Class III 2.png";
import OnesideAnglesClassIII3 from "./assets/One side Angle's Class III/One side Angle's Class III 3.png";
import OnesideAnglesClassIII4 from "./assets/One side Angle's Class III/One side Angle's Class III 4.png";
import OnesideAnglesClassIII5 from "./assets/One side Angle's Class III/One side Angle's Class III 5.png";
import OnesideAnglesClassIII6 from "./assets/One side Angle's Class III/One side Angle's Class III 6.png";
import OnesideAnglesClassIII7 from "./assets/One side Angle's Class III/One side Angle's Class III 7.png";
import OnesideAnglesClassIII8 from "./assets/One side Angle's Class III/One side Angle's Class III 8.png";
import OnesideAnglesClassIII9 from "./assets/One side Angle's Class III/One side Angle's Class III 9.png";
import OnesideAnglesClassIII10 from "./assets/One side Angle's Class III/One side Angle's Class III 10.png";
import OnesideAnglesClassIII11 from "./assets/One side Angle's Class III/One side Angle's Class III 11.png";
import OnesideAnglesClassIII12 from "./assets/One side Angle's Class III/One side Angle's Class III 12.png";
import OnesideAnglesClassIII13 from "./assets/One side Angle's Class III/One side Angle's Class III 13.png";
import OnesideAnglesClassIII14 from "./assets/One side Angle's Class III/One side Angle's Class III 14.png";
import OnesideAnglesClassIII15 from "./assets/One side Angle's Class III/One side Angle's Class III 15.png";
import OnesideAnglesClassIII16 from "./assets/One side Angle's Class III/One side Angle's Class III 16.png";
import Openbite1 from "./assets/Openbite/Openbite1.png";
import Openbite2 from "./assets/Openbite/Openbite2.png";
import Openbite3 from "./assets/Openbite/Openbite3.png";
import Openbite4 from "./assets/Openbite/Openbite4.png";
import Openbite5 from "./assets/Openbite/Openbite5.png";
import Openbite6 from "./assets/Openbite/Openbite6.png";
import Openbite7 from "./assets/Openbite/Openbite7.png";
import Openbite8 from "./assets/Openbite/Openbite8.png";
import Openbite9 from "./assets/Openbite/Openbite9.png";
import Openbite10 from "./assets/Openbite/Openbite10.png";
import Openbite11 from "./assets/Openbite/Openbite11.png";
import Openbite12 from "./assets/Openbite/Openbite12.png";
import Openbite13 from "./assets/Openbite/Openbite13.png";
import Openbite14 from "./assets/Openbite/Openbite14.png";
import Openbite15 from "./assets/Openbite/Openbite15.png";
import Overbitedeepbite1 from "./assets/Overbite (deep bite)/Overbite (deep bite)1.png";
import Overbitedeepbite2 from "./assets/Overbite (deep bite)/Overbite (deep bite)2.png";
import Overbitedeepbite3 from "./assets/Overbite (deep bite)/Overbite (deep bite)3.png";
import Overbitedeepbite4 from "./assets/Overbite (deep bite)/Overbite (deep bite)4.png";
import Overbitedeepbite5 from "./assets/Overbite (deep bite)/Overbite (deep bite)5.png";
import Overbitedeepbite6 from "./assets/Overbite (deep bite)/Overbite (deep bite)6.png";
import Overbitedeepbite7 from "./assets/Overbite (deep bite)/Overbite (deep bite)7.png";
import Overbitedeepbite8 from "./assets/Overbite (deep bite)/Overbite (deep bite)8.png";
import Overbitedeepbite9 from "./assets/Overbite (deep bite)/Overbite (deep bite)9.png";
import Overbitedeepbite10 from "./assets/Overbite (deep bite)/Overbite (deep bite)10.png";
import Overbitedeepbite11 from "./assets/Overbite (deep bite)/Overbite (deep bite)11.png";
import Overbitedeepbite12 from "./assets/Overbite (deep bite)/Overbite (deep bite)12.png";
import Overbitedeepbite13 from "./assets/Overbite (deep bite)/Overbite (deep bite)13.png";
import Overbitedeepbite14 from "./assets/Overbite (deep bite)/Overbite (deep bite)14.png";
import Overjetsagittalfissure1 from "./assets/Overjet (sagittal fissure)/Overjet (sagittal fissure)1.png";
import Overjetsagittalfissure2 from "./assets/Overjet (sagittal fissure)/Overjet (sagittal fissure)2.png";
import Overjetsagittalfissure3 from "./assets/Overjet (sagittal fissure)/Overjet (sagittal fissure)3.png";
import Overjetsagittalfissure4 from "./assets/Overjet (sagittal fissure)/Overjet (sagittal fissure)4.png";
import Overjetsagittalfissure5 from "./assets/Overjet (sagittal fissure)/Overjet (sagittal fissure)5.png";
import Overjetsagittalfissure6 from "./assets/Overjet (sagittal fissure)/Overjet (sagittal fissure)6.png";
import Overjetsagittalfissure7 from "./assets/Overjet (sagittal fissure)/Overjet (sagittal fissure)7.png";
import Overjetsagittalfissure8 from "./assets/Overjet (sagittal fissure)/Overjet (sagittal fissure)8.png";
import Overjetsagittalfissure9 from "./assets/Overjet (sagittal fissure)/Overjet (sagittal fissure)9.png";
import Overjetsagittalfissure10 from "./assets/Overjet (sagittal fissure)/Overjet (sagittal fissure)10.png";
import Overjetsagittalfissure11 from "./assets/Overjet (sagittal fissure)/Overjet (sagittal fissure)11.png";
import Overjetsagittalfissure12 from "./assets/Overjet (sagittal fissure)/Overjet (sagittal fissure)12.png";
import Overjetsagittalfissure13 from "./assets/Overjet (sagittal fissure)/Overjet (sagittal fissure)13.png";
import Overjetsagittalfissure14 from "./assets/Overjet (sagittal fissure)/Overjet (sagittal fissure)14.png";
import Overjetsagittalfissure15 from "./assets/Overjet (sagittal fissure)/Overjet (sagittal fissure)15.png";
import Protrusionoftheanteriorteeth1 from "./assets/Protrusion of the anterior teeth/Protrusion of the anterior teeth1.png";
import Protrusionoftheanteriorteeth2 from "./assets/Protrusion of the anterior teeth/Protrusion of the anterior teeth2.png";
import Protrusionoftheanteriorteeth3 from "./assets/Protrusion of the anterior teeth/Protrusion of the anterior teeth3.png";
import Protrusionoftheanteriorteeth4 from "./assets/Protrusion of the anterior teeth/Protrusion of the anterior teeth4.png";
import Protrusionoftheanteriorteeth5 from "./assets/Protrusion of the anterior teeth/Protrusion of the anterior teeth5.png";
import Protrusionoftheanteriorteeth6 from "./assets/Protrusion of the anterior teeth/Protrusion of the anterior teeth6.png";
import Protrusionoftheanteriorteeth7 from "./assets/Protrusion of the anterior teeth/Protrusion of the anterior teeth7.png";
import Protrusionoftheanteriorteeth8 from "./assets/Protrusion of the anterior teeth/Protrusion of the anterior teeth8.png";
import Protrusionoftheanteriorteeth9 from "./assets/Protrusion of the anterior teeth/Protrusion of the anterior teeth9.png";
import Protrusionoftheanteriorteeth10 from "./assets/Protrusion of the anterior teeth/Protrusion of the anterior teeth10.png";
import Protrusionoftheanteriorteeth11 from "./assets/Protrusion of the anterior teeth/Protrusion of the anterior teeth11.png";
import Protrusionoftheanteriorteeth12 from "./assets/Protrusion of the anterior teeth/Protrusion of the anterior teeth12.png";
import Protrusionoftheanteriorteeth13 from "./assets/Protrusion of the anterior teeth/Protrusion of the anterior teeth13.png";
import Protrusionoftheanteriorteeth14 from "./assets/Protrusion of the anterior teeth/Protrusion of the anterior teeth14.png";
import Retrusionoftheanteriorteeth1 from "./assets/Retrusion of the anterior teeth/Retrusion of the anterior teeth1.png";
import Retrusionoftheanteriorteeth2 from "./assets/Retrusion of the anterior teeth/Retrusion of the anterior teeth2.png";
import Retrusionoftheanteriorteeth3 from "./assets/Retrusion of the anterior teeth/Retrusion of the anterior teeth3.png";
import Retrusionoftheanteriorteeth4 from "./assets/Retrusion of the anterior teeth/Retrusion of the anterior teeth4.png";
import Retrusionoftheanteriorteeth5 from "./assets/Retrusion of the anterior teeth/Retrusion of the anterior teeth5.png";
import Retrusionoftheanteriorteeth6 from "./assets/Retrusion of the anterior teeth/Retrusion of the anterior teeth6.png";
import Retrusionoftheanteriorteeth7 from "./assets/Retrusion of the anterior teeth/Retrusion of the anterior teeth7.png";
import Retrusionoftheanteriorteeth8 from "./assets/Retrusion of the anterior teeth/Retrusion of the anterior teeth8.png";
import Retrusionoftheanteriorteeth9 from "./assets/Retrusion of the anterior teeth/Retrusion of the anterior teeth9.png";
import Retrusionoftheanteriorteeth10 from "./assets/Retrusion of the anterior teeth/Retrusion of the anterior teeth10.png";
import Retrusionoftheanteriorteeth11 from "./assets/Retrusion of the anterior teeth/Retrusion of the anterior teeth11.png";
import Retrusionoftheanteriorteeth12 from "./assets/Retrusion of the anterior teeth/Retrusion of the anterior teeth12.png";
import Retrusionoftheanteriorteeth13 from "./assets/Retrusion of the anterior teeth/Retrusion of the anterior teeth13.png";
import Retrusionoftheanteriorteeth14 from "./assets/Retrusion of the anterior teeth/Retrusion of the anterior teeth14.png";
import Retrusionoftheanteriorteeth15 from "./assets/Retrusion of the anterior teeth/Retrusion of the anterior teeth15.png";
import Rotatedtooth1 from "./assets/Rotated tooth/Rotated tooth1.png";
import Rotatedtooth2 from "./assets/Rotated tooth/Rotated tooth2.png";
import Rotatedtooth3 from "./assets/Rotated tooth/Rotated tooth3.png";
import Rotatedtooth4 from "./assets/Rotated tooth/Rotated tooth4.png";
import Rotatedtooth5 from "./assets/Rotated tooth/Rotated tooth5.png";
import Rotatedtooth6 from "./assets/Rotated tooth/Rotated tooth6.png";
import Rotatedtooth7 from "./assets/Rotated tooth/Rotated tooth7.png";
import Rotatedtooth8 from "./assets/Rotated tooth/Rotated tooth8.png";
import Rotatedtooth9 from "./assets/Rotated tooth/Rotated tooth9.png";
import Rotatedtooth10 from "./assets/Rotated tooth/Rotated tooth10.png";
import Rotatedtooth11 from "./assets/Rotated tooth/Rotated tooth11.png";
import Rotatedtooth12 from "./assets/Rotated tooth/Rotated tooth12.png";
import Rotatedtooth13 from "./assets/Rotated tooth/Rotated tooth13.png";
import Rotatedtooth14 from "./assets/Rotated tooth/Rotated tooth14.png";
import Rotatedtooth15 from "./assets/Rotated tooth/Rotated tooth15.png";
import Smallmultipleviolationsofthe1 from "./assets/Small multiple violations of the/Small multiple violations of the 1.png";
import Smallmultipleviolationsofthe2 from "./assets/Small multiple violations of the/Small multiple violations of the 2.png";
import Smallmultipleviolationsofthe3 from "./assets/Small multiple violations of the/Small multiple violations of the 3.png";
import Smallmultipleviolationsofthe4 from "./assets/Small multiple violations of the/Small multiple violations of the 4.png";
import Smallmultipleviolationsofthe5 from "./assets/Small multiple violations of the/Small multiple violations of the 5.png";
import Smallmultipleviolationsofthe6 from "./assets/Small multiple violations of the/Small multiple violations of the 6.png";
import Smallmultipleviolationsofthe7 from "./assets/Small multiple violations of the/Small multiple violations of the 7.png";
import Smallmultipleviolationsofthe8 from "./assets/Small multiple violations of the/Small multiple violations of the 8.png";
import Smallmultipleviolationsofthe9 from "./assets/Small multiple violations of the/Small multiple violations of the 9.png";
import Smallmultipleviolationsofthe10 from "./assets/Small multiple violations of the/Small multiple violations of the 10.png";
import Smallmultipleviolationsofthe11 from "./assets/Small multiple violations of the/Small multiple violations of the 11.png";
import Smallmultipleviolationsofthe12 from "./assets/Small multiple violations of the/Small multiple violations of the 12.png";
import Smallmultipleviolationsofthe13 from "./assets/Small multiple violations of the/Small multiple violations of the 13.png";
import Smallmultipleviolationsofthe14 from "./assets/Small multiple violations of the/Small multiple violations of the 14.png";
import Spacing1 from "./assets/Spacing/Spacing1.png";
import Spacing2 from "./assets/Spacing/Spacing2.png";
import Spacing3 from "./assets/Spacing/Spacing3.png";
import Spacing4 from "./assets/Spacing/Spacing4.png";
import Spacing5 from "./assets/Spacing/Spacing5.png";
import Spacing6 from "./assets/Spacing/Spacing6.png";
import Spacing7 from "./assets/Spacing/Spacing7.png";
import Spacing8 from "./assets/Spacing/Spacing8.png";
import Spacing9 from "./assets/Spacing/Spacing9.png";
import Spacing10 from "./assets/Spacing/Spacing10.png";
import Spacing11 from "./assets/Spacing/Spacing11.png";
import Spacing12 from "./assets/Spacing/Spacing12.png";
import Spacing13 from "./assets/Spacing/Spacing13.png";
import Spacing14 from "./assets/Spacing/Spacing14.png";
import Violationtoothangulation1 from "./assets/Violation tooth angulation/Violation tooth angulation1.png";
import Violationtoothangulation2 from "./assets/Violation tooth angulation/Violation tooth angulation2.png";
import Violationtoothangulation3 from "./assets/Violation tooth angulation/Violation tooth angulation3.png";
import Violationtoothangulation4 from "./assets/Violation tooth angulation/Violation tooth angulation4.png";
import Violationtoothangulation5 from "./assets/Violation tooth angulation/Violation tooth angulation5.png";
import Violationtoothangulation6 from "./assets/Violation tooth angulation/Violation tooth angulation6.png";
import Violationtoothangulation7 from "./assets/Violation tooth angulation/Violation tooth angulation7.png";
import Violationtoothangulation8 from "./assets/Violation tooth angulation/Violation tooth angulation8.png";
import Violationtoothangulation9 from "./assets/Violation tooth angulation/Violation tooth angulation9.png";
import Violationtoothangulation10 from "./assets/Violation tooth angulation/Violation tooth angulation10.png";
import Violationtoothangulation11 from "./assets/Violation tooth angulation/Violation tooth angulation11.png";
import Violationtoothangulation12 from "./assets/Violation tooth angulation/Violation tooth angulation12.png";
import Violationtoothangulation13 from "./assets/Violation tooth angulation/Violation tooth angulation13.png";

import uploadUpper from "./assets/uploadUpper.png";
import uploadLower from "./assets/uploadLower.png";
import panoram from "./assets/panoram.png";
import cephal from "./assets/cephal.png";
import cbct from "./assets/cbct.png";
import proto from "./assets/proto.png";

export const data = (_t) => [
  {
    id: 1,
    pic: classification1,
    title: _t("Небольшие множественные нарушения положения зубов"),
    value: "SMALL_MUL_VIOLATIONS",
    necData: [
      {
        pic: uploadUpper,
        type: "upper_jaw_stl",
        title: _t("Загрузить верхнюю челюсть"),
      },
      {
        pic: uploadLower,
        type: "lower_jaw_stl",
        title: _t("Загрузить нижнюю челюсть"),
      },
      {
        pic: panoram,
        type: "panoramic",
        title: _t("Панорамный снимок"),
      },
    ],
    link: "small-multiple-violations",
    slides: [
      { id: 1, pic: Smallmultipleviolationsofthe1 },
      { id: 2, pic: Smallmultipleviolationsofthe2 },
      { id: 3, pic: Smallmultipleviolationsofthe3 },
      { id: 4, pic: Smallmultipleviolationsofthe4 },
      { id: 5, pic: Smallmultipleviolationsofthe5 },
      { id: 6, pic: Smallmultipleviolationsofthe6 },
      { id: 7, pic: Smallmultipleviolationsofthe7 },
      { id: 8, pic: Smallmultipleviolationsofthe8 },
      { id: 9, pic: Smallmultipleviolationsofthe9 },
      { id: 10, pic: Smallmultipleviolationsofthe10 },
      { id: 11, pic: Smallmultipleviolationsofthe11 },
      { id: 12, pic: Smallmultipleviolationsofthe12 },
      { id: 13, pic: Smallmultipleviolationsofthe13 },
      { id: 14, pic: Smallmultipleviolationsofthe14 },
    ],
    clarification: [
      _t(
        "Нарушение ангуляции и наклона отдельных зубов в пределах 10 градусов, ротация отдельных зубов в пределах 15 градусов, нарушение высоты коронки зуба в пределах 1 мм.",
      ),
    ],
  },
  {
    id: 2,
    pic: classification2,
    title: _t("Экструзированный зуб"),
    value: "EXTRUDED_TOOTH",
    necData: [
      {
        pic: uploadUpper,
        type: "upper_jaw_stl",
        title: _t("Загрузить верхнюю челюсть"),
      },
      {
        pic: uploadLower,
        type: "lower_jaw_stl",
        title: _t("Загрузить нижнюю челюсть"),
      },
      {
        pic: panoram,
        type: "panoramic",
        title: _t("Панорамный снимок"),
      },
    ],
    link: "extruded-tooth",
    slides: [
      { id: 1, pic: Extrudedtooth1 },
      { id: 2, pic: Extrudedtooth2 },
      { id: 3, pic: Extrudedtooth3 },
      { id: 4, pic: Extrudedtooth4 },
      { id: 5, pic: Extrudedtooth5 },
      { id: 6, pic: Extrudedtooth6 },
      { id: 7, pic: Extrudedtooth7 },
      { id: 8, pic: Extrudedtooth8 },
      { id: 9, pic: Extrudedtooth9 },
      { id: 10, pic: Extrudedtooth10 },
      { id: 11, pic: Extrudedtooth11 },
      { id: 12, pic: Extrudedtooth12 },
      { id: 13, pic: Extrudedtooth13 },
      { id: 14, pic: Extrudedtooth14 },
      { id: 15, pic: Extrudedtooth15 },
      { id: 16, pic: Extrudedtooth16 },
    ],
  },
  {
    id: 3,
    pic: classification3,
    title: _t("Интрузированный зуб"),
    value: "INTRUDED_TOOTH",
    necData: [
      {
        pic: uploadUpper,
        type: "upper_jaw_stl",
        title: _t("Загрузить верхнюю челюсть"),
      },
      {
        pic: uploadLower,
        type: "lower_jaw_stl",
        title: _t("Загрузить нижнюю челюсть"),
      },
      {
        pic: panoram,
        type: "panoramic",
        title: _t("Панорамный снимок"),
      },
    ],
    link: "intruded-tooth",
    slides: [
      { id: 1, pic: Intruded1 },
      { id: 2, pic: Intruded2 },
      { id: 3, pic: Intruded3 },
      { id: 4, pic: Intruded4 },
      { id: 5, pic: Intruded5 },
      { id: 6, pic: Intruded6 },
      { id: 7, pic: Intruded7 },
      { id: 8, pic: Intruded8 },
      { id: 9, pic: Intruded9 },
      { id: 10, pic: Intruded10 },
      { id: 11, pic: Intruded11 },
      { id: 12, pic: Intruded12 },
      { id: 13, pic: Intruded13 },
    ],
  },
  {
    id: 4,
    pic: classification4,
    title: _t("Неправильный наклон зуба"),
    value: "INCLINATION",
    necData: [
      {
        pic: uploadUpper,
        type: "upper_jaw_stl",
        title: _t("Загрузить верхнюю челюсть"),
      },
      {
        pic: uploadLower,
        type: "lower_jaw_stl",
        title: _t("Загрузить нижнюю челюсть"),
      },
      {
        pic: panoram,
        type: "panoramic",
        title: _t("Панорамный снимок"),
      },
    ],
    link: "incorrect-inclination-of-the-tooth",
    slides: [
      { id: 1, pic: Incorrectinclinationofthetooth1 },
      { id: 2, pic: Incorrectinclinationofthetooth2 },
      { id: 3, pic: Incorrectinclinationofthetooth3 },
      { id: 4, pic: Incorrectinclinationofthetooth4 },
      { id: 5, pic: Incorrectinclinationofthetooth5 },
      { id: 6, pic: Incorrectinclinationofthetooth6 },
      { id: 7, pic: Incorrectinclinationofthetooth7 },
      { id: 8, pic: Incorrectinclinationofthetooth8 },
      { id: 9, pic: Incorrectinclinationofthetooth9 },
      { id: 10, pic: Incorrectinclinationofthetooth10 },
      { id: 11, pic: Incorrectinclinationofthetooth11 },
      { id: 12, pic: Incorrectinclinationofthetooth12 },
      { id: 13, pic: Incorrectinclinationofthetooth13 },
    ],
  },
  {
    id: 5,
    pic: classification5,
    title: _t("Нарушение ангуляции зуба"),
    value: "VOLATION_ANGULATION",
    necData: [
      {
        pic: uploadUpper,
        type: "upper_jaw_stl",
        title: _t("Загрузить верхнюю челюсть"),
      },
      {
        pic: uploadLower,
        type: "lower_jaw_stl",
        title: _t("Загрузить нижнюю челюсть"),
      },
      {
        pic: panoram,
        type: "panoramic",
        title: _t("Панорамный снимок"),
      },
    ],
    link: "violation-tooth-angulation",
    slides: [
      { id: 1, pic: Violationtoothangulation1 },
      { id: 2, pic: Violationtoothangulation2 },
      { id: 3, pic: Violationtoothangulation3 },
      { id: 4, pic: Violationtoothangulation4 },
      { id: 5, pic: Violationtoothangulation5 },
      { id: 6, pic: Violationtoothangulation6 },
      { id: 7, pic: Violationtoothangulation7 },
      { id: 8, pic: Violationtoothangulation8 },
      { id: 9, pic: Violationtoothangulation9 },
      { id: 10, pic: Violationtoothangulation10 },
      { id: 11, pic: Violationtoothangulation11 },
      { id: 12, pic: Violationtoothangulation12 },
      { id: 13, pic: Violationtoothangulation13 },
    ],
  },
  {
    id: 6,
    pic: classification6,
    title: _t("Ротированный зуб"),
    value: "ROTATED_TOOTH",
    necData: [
      {
        pic: uploadUpper,
        type: "upper_jaw_stl",
        title: _t("Загрузить верхнюю челюсть"),
      },
      {
        pic: uploadLower,
        type: "lower_jaw_stl",
        title: _t("Загрузить нижнюю челюсть"),
      },
      {
        pic: panoram,
        type: "panoramic",
        title: _t("Панорамный снимок"),
      },
    ],
    link: "rotated-tooth",
    slides: [
      { id: 1, pic: Rotatedtooth1 },
      { id: 2, pic: Rotatedtooth2 },
      { id: 3, pic: Rotatedtooth3 },
      { id: 4, pic: Rotatedtooth4 },
      { id: 5, pic: Rotatedtooth5 },
      { id: 6, pic: Rotatedtooth6 },
      { id: 7, pic: Rotatedtooth7 },
      { id: 8, pic: Rotatedtooth8 },
      { id: 9, pic: Rotatedtooth9 },
      { id: 10, pic: Rotatedtooth10 },
      { id: 11, pic: Rotatedtooth11 },
      { id: 12, pic: Rotatedtooth12 },
      { id: 13, pic: Rotatedtooth13 },
      { id: 14, pic: Rotatedtooth14 },
      { id: 15, pic: Rotatedtooth15 },
    ],
  },
  {
    id: 7,
    pic: classification7,
    title: _t("Тремы, диастемы"),
    value: "SPACING",
    necData: [
      {
        pic: uploadUpper,
        type: "upper_jaw_stl",
        title: _t("Загрузить верхнюю челюсть"),
      },
      {
        pic: uploadLower,
        type: "lower_jaw_stl",
        title: _t("Загрузить нижнюю челюсть"),
      },
      {
        pic: panoram,
        type: "panoramic",
        title: _t("Панорамный снимок"),
      },
      {
        pic: proto,
        type: "photo_protocol",
        title: _t("Фото Протокол"),
      },
      {
        pic: cephal,
        type: "cephalometric",
        title: _t("ТРГ боковой проекции"),
      },
    ],
    link: "spacing",
    slides: [
      { id: 1, pic: Spacing1 },
      { id: 2, pic: Spacing2 },
      { id: 3, pic: Spacing3 },
      { id: 4, pic: Spacing4 },
      { id: 5, pic: Spacing5 },
      { id: 6, pic: Spacing6 },
      { id: 7, pic: Spacing7 },
      { id: 8, pic: Spacing8 },
      { id: 9, pic: Spacing9 },
      { id: 10, pic: Spacing10 },
      { id: 11, pic: Spacing11 },
      { id: 12, pic: Spacing12 },
      { id: 13, pic: Spacing13 },
      { id: 14, pic: Spacing14 },
    ],
  },
  {
    id: 8,
    pic: classification8,
    title: _t("Скученность зубов на одной челюсти"),
    value: "CROWDING_ONE",
    necData: [
      {
        pic: uploadUpper,
        type: "upper_jaw_stl",
        title: _t("Загрузить верхнюю челюсть"),
      },
      {
        pic: uploadLower,
        type: "lower_jaw_stl",
        title: _t("Загрузить нижнюю челюсть"),
      },
      {
        pic: panoram,
        type: "panoramic",
        title: _t("Панорамный снимок"),
      },
    ],
    link: "crowding-of-teeth-on-one-jaw",
    slides: [
      { id: 1, pic: Crowdingofteethononejaw1 },
      { id: 2, pic: Crowdingofteethononejaw2 },
      { id: 3, pic: Crowdingofteethononejaw3 },
      { id: 4, pic: Crowdingofteethononejaw4 },
      { id: 5, pic: Crowdingofteethononejaw5 },
      { id: 6, pic: Crowdingofteethononejaw6 },
      { id: 7, pic: Crowdingofteethononejaw7 },
      { id: 8, pic: Crowdingofteethononejaw8 },
      { id: 9, pic: Crowdingofteethononejaw9 },
      { id: 10, pic: Crowdingofteethononejaw10 },
      { id: 11, pic: Crowdingofteethononejaw11 },
      { id: 12, pic: Crowdingofteethononejaw12 },
      { id: 13, pic: Crowdingofteethononejaw13 },
      { id: 14, pic: Crowdingofteethononejaw14 },
    ],
    clarification: [
      _t(
        "Дефицит места на челюсти не более 3 мм для правильного положения зубов. Если дефицит составляет более 3 мм, выберите другую патологию.",
      ),
    ],
  },
  {
    id: 9,
    pic: classification9,
    title: _t("Протрузия передних зубов"),
    value: "PROTRUSION",
    necData: [
      {
        pic: uploadUpper,
        type: "upper_jaw_stl",
        title: _t("Загрузить верхнюю челюсть"),
      },
      {
        pic: uploadLower,
        type: "lower_jaw_stl",
        title: _t("Загрузить нижнюю челюсть"),
      },
      {
        pic: panoram,
        type: "panoramic",
        title: _t("Панорамный снимок"),
      },
      {
        pic: cephal,
        type: "cephalometric",
        title: _t("ТРГ боковой проекции"),
      },
    ],
    link: "protrusion-of-the-anterior-teeth",
    slides: [
      { id: 1, pic: Protrusionoftheanteriorteeth1 },
      { id: 2, pic: Protrusionoftheanteriorteeth2 },
      { id: 3, pic: Protrusionoftheanteriorteeth3 },
      { id: 4, pic: Protrusionoftheanteriorteeth4 },
      { id: 5, pic: Protrusionoftheanteriorteeth5 },
      { id: 6, pic: Protrusionoftheanteriorteeth6 },
      { id: 7, pic: Protrusionoftheanteriorteeth7 },
      { id: 8, pic: Protrusionoftheanteriorteeth8 },
      { id: 9, pic: Protrusionoftheanteriorteeth9 },
      { id: 10, pic: Protrusionoftheanteriorteeth10 },
      { id: 11, pic: Protrusionoftheanteriorteeth11 },
      { id: 12, pic: Protrusionoftheanteriorteeth12 },
      { id: 13, pic: Protrusionoftheanteriorteeth13 },
      { id: 14, pic: Protrusionoftheanteriorteeth14 },
    ],
    clarification: [_t("Без изменения соотношения жевательных зубов")],
  },
  {
    id: 10,
    pic: classification10,
    title: _t("Ретрузия передних зубов"),
    value: "RETRUSION",
    necData: [
      {
        pic: uploadUpper,
        type: "upper_jaw_stl",
        title: _t("Загрузить верхнюю челюсть"),
      },
      {
        pic: uploadLower,
        type: "lower_jaw_stl",
        title: _t("Загрузить нижнюю челюсть"),
      },
      {
        pic: panoram,
        type: "panoramic",
        title: _t("Панорамный снимок"),
      },
      {
        pic: cephal,
        type: "cephalometric",
        title: _t("ТРГ боковой проекции"),
      },
    ],
    link: "retrusion-of-the-anterior-teeth",
    slides: [
      { id: 1, pic: Retrusionoftheanteriorteeth1 },
      { id: 2, pic: Retrusionoftheanteriorteeth2 },
      { id: 3, pic: Retrusionoftheanteriorteeth3 },
      { id: 4, pic: Retrusionoftheanteriorteeth4 },
      { id: 5, pic: Retrusionoftheanteriorteeth5 },
      { id: 6, pic: Retrusionoftheanteriorteeth6 },
      { id: 7, pic: Retrusionoftheanteriorteeth7 },
      { id: 8, pic: Retrusionoftheanteriorteeth8 },
      { id: 9, pic: Retrusionoftheanteriorteeth9 },
      { id: 10, pic: Retrusionoftheanteriorteeth10 },
      { id: 11, pic: Retrusionoftheanteriorteeth11 },
      { id: 12, pic: Retrusionoftheanteriorteeth12 },
      { id: 13, pic: Retrusionoftheanteriorteeth13 },
      { id: 14, pic: Retrusionoftheanteriorteeth14 },
      { id: 15, pic: Retrusionoftheanteriorteeth15 },
    ],
    clarification: [_t("Без изменения соотношения жевательных зубов")],
  },
  {
    id: 11,
    pic: classification11,
    title: _t("II класс по Энглю с одной стороны"),
    value: "ONE_SIDE_ANGLE_C2",
    necData: [
      {
        pic: uploadUpper,
        type: "upper_jaw_stl",
        title: _t("Загрузить верхнюю челюсть"),
      },
      {
        pic: uploadLower,
        type: "lower_jaw_stl",
        title: _t("Загрузить нижнюю челюсть"),
      },
      {
        pic: panoram,
        type: "panoramic",
        title: _t("Панорамный снимок"),
      },
      {
        pic: cbct,
        type: "cbct",
        title: _t("КТ"),
      },
    ],
    link: "one-side-angles-class-II",
    slides: [
      { id: 1, pic: OnesideAnglesClassII1 },
      { id: 2, pic: OnesideAnglesClassII2 },
      { id: 3, pic: OnesideAnglesClassII3 },
      { id: 4, pic: OnesideAnglesClassII4 },
      { id: 5, pic: OnesideAnglesClassII5 },
      { id: 6, pic: OnesideAnglesClassII6 },
      { id: 7, pic: OnesideAnglesClassII7 },
      { id: 8, pic: OnesideAnglesClassII8 },
      { id: 9, pic: OnesideAnglesClassII9 },
      { id: 10, pic: OnesideAnglesClassII10 },
      { id: 11, pic: OnesideAnglesClassII11 },
      { id: 12, pic: OnesideAnglesClassII12 },
      { id: 13, pic: OnesideAnglesClassII13 },
      { id: 14, pic: OnesideAnglesClassII14 },
      { id: 15, pic: OnesideAnglesClassII15 },
    ],
    clarification: [_t("Не скелетный II класс по Энгелю")],
  },
  {
    id: 12,
    pic: classification12,
    title: _t("III класс по Энглю с одной стороны"),
    value: "ONE_SIDE_ANGLE_C3",
    necData: [
      {
        pic: uploadUpper,
        type: "upper_jaw_stl",
        title: _t("Загрузить верхнюю челюсть"),
      },
      {
        pic: uploadLower,
        type: "lower_jaw_stl",
        title: _t("Загрузить нижнюю челюсть"),
      },
      {
        pic: panoram,
        type: "panoramic",
        title: _t("Панорамный снимок"),
      },
      {
        pic: cbct,
        type: "cbct",
        title: _t("КТ"),
      },
    ],
    link: "one-side-angles-class-III",
    slides: [
      { id: 1, pic: OnesideAnglesClassIII1 },
      { id: 2, pic: OnesideAnglesClassIII2 },
      { id: 3, pic: OnesideAnglesClassIII3 },
      { id: 4, pic: OnesideAnglesClassIII4 },
      { id: 5, pic: OnesideAnglesClassIII5 },
      { id: 6, pic: OnesideAnglesClassIII6 },
      { id: 7, pic: OnesideAnglesClassIII7 },
      { id: 8, pic: OnesideAnglesClassIII8 },
      { id: 9, pic: OnesideAnglesClassIII9 },
      { id: 10, pic: OnesideAnglesClassIII10 },
      { id: 11, pic: OnesideAnglesClassIII11 },
      { id: 12, pic: OnesideAnglesClassIII12 },
      { id: 13, pic: OnesideAnglesClassIII13 },
      { id: 14, pic: OnesideAnglesClassIII14 },
      { id: 15, pic: OnesideAnglesClassIII15 },
      { id: 16, pic: OnesideAnglesClassIII16 },
    ],
    clarification: [_t("Не скелетный III класс по Энгелю")],
  },
  {
    id: 13,
    pic: classification13,
    title: _t("Перекрестный прикус"),
    value: "CROSS_BITE",
    necData: [
      {
        pic: uploadUpper,
        type: "upper_jaw_stl",
        title: _t("Загрузить верхнюю челюсть"),
      },
      {
        pic: uploadLower,
        type: "lower_jaw_stl",
        title: _t("Загрузить нижнюю челюсть"),
      },
      {
        pic: panoram,
        type: "panoramic",
        title: _t("Панорамный снимок"),
      },
    ],
    link: "cross-bite",
    slides: [
      { id: 1, pic: Crossbite1 },
      { id: 2, pic: Crossbite2 },
      { id: 3, pic: Crossbite3 },
      { id: 4, pic: Crossbite4 },
      { id: 5, pic: Crossbite5 },
      { id: 6, pic: Crossbite6 },
      { id: 7, pic: Crossbite7 },
      { id: 8, pic: Crossbite8 },
      { id: 9, pic: Crossbite9 },
      { id: 10, pic: Crossbite10 },
      { id: 11, pic: Crossbite11 },
      { id: 12, pic: Crossbite12 },
      { id: 13, pic: Crossbite13 },
      { id: 14, pic: Crossbite14 },
    ],
    clarification: [_t("Не более 2-3 зубов подряд."), _t("Не дистальный перекрестный прикус")],
  },
  {
    id: 14,
    pic: classification15,
    title: _t("II класс по Энглю. Подкласс I"),
    value: "ANGLE_C2_D1",
    necData: [
      {
        pic: uploadUpper,
        type: "upper_jaw_stl",
        title: _t("Загрузить верхнюю челюсть"),
      },
      {
        pic: uploadLower,
        type: "lower_jaw_stl",
        title: _t("Загрузить нижнюю челюсть"),
      },
      {
        pic: panoram,
        type: "panoramic",
        title: _t("Панорамный снимок"),
      },
      {
        pic: cephal,
        type: "cephalometric",
        title: _t("ТРГ боковой проекции"),
      },
      {
        pic: cbct,
        type: "cbct",
        title: _t("КТ"),
      },
      {
        pic: proto,
        type: "photo_protocol",
        title: _t("Фото Протокол"),
      },
    ],
    link: "angles-class-II-division-I",
    slides: [
      { id: 1, pic: AnglesClassIIDivisionI1 },
      { id: 2, pic: AnglesClassIIDivisionI2 },
      { id: 3, pic: AnglesClassIIDivisionI3 },
      { id: 4, pic: AnglesClassIIDivisionI4 },
      { id: 5, pic: AnglesClassIIDivisionI5 },
      { id: 6, pic: AnglesClassIIDivisionI6 },
      { id: 7, pic: AnglesClassIIDivisionI7 },
      { id: 8, pic: AnglesClassIIDivisionI8 },
      { id: 9, pic: AnglesClassIIDivisionI9 },
      { id: 10, pic: AnglesClassIIDivisionI10 },
      { id: 11, pic: AnglesClassIIDivisionI11 },
      { id: 12, pic: AnglesClassIIDivisionI12 },
      { id: 13, pic: AnglesClassIIDivisionI13 },
      { id: 14, pic: AnglesClassIIDivisionI14 },
      { id: 15, pic: AnglesClassIIDivisionI15 },
      { id: 16, pic: AnglesClassIIDivisionI16 },
      { id: 17, pic: AnglesClassIIDivisionI17 },
      { id: 18, pic: AnglesClassIIDivisionI18 },
      { id: 19, pic: AnglesClassIIDivisionI19 },
      { id: 20, pic: AnglesClassIIDivisionI20 },
    ],
  },
  {
    id: 15,
    pic: classification14,
    title: _t("II класс по Энглю. Подкласс II"),
    value: "ANGLE_C2_D2",
    necData: [
      {
        pic: uploadUpper,
        type: "upper_jaw_stl",
        title: _t("Загрузить верхнюю челюсть"),
      },
      {
        pic: uploadLower,
        type: "lower_jaw_stl",
        title: _t("Загрузить нижнюю челюсть"),
      },
      {
        pic: panoram,
        type: "panoramic",
        title: _t("Панорамный снимок"),
      },
      {
        pic: cephal,
        type: "cephalometric",
        title: _t("ТРГ боковой проекции"),
      },
      {
        pic: cbct,
        type: "cbct",
        title: _t("КТ"),
      },
      {
        pic: proto,
        type: "photo_protocol",
        title: _t("Фото Протокол"),
      },
    ],
    link: "angles-class-II-division-II",
    slides: [
      { id: 1, pic: AnglesClassIIDivisionII1 },
      { id: 2, pic: AnglesClassIIDivisionII2 },
      { id: 3, pic: AnglesClassIIDivisionII3 },
      { id: 4, pic: AnglesClassIIDivisionII4 },
      { id: 5, pic: AnglesClassIIDivisionII5 },
      { id: 6, pic: AnglesClassIIDivisionII6 },
      { id: 7, pic: AnglesClassIIDivisionII7 },
      { id: 8, pic: AnglesClassIIDivisionII8 },
      { id: 9, pic: AnglesClassIIDivisionII9 },
      { id: 10, pic: AnglesClassIIDivisionII10 },
      { id: 11, pic: AnglesClassIIDivisionII11 },
      { id: 12, pic: AnglesClassIIDivisionII12 },
      { id: 13, pic: AnglesClassIIDivisionII13 },
      { id: 14, pic: AnglesClassIIDivisionII14 },
      { id: 15, pic: AnglesClassIIDivisionII15 },
      { id: 16, pic: AnglesClassIIDivisionII16 },
      { id: 17, pic: AnglesClassIIDivisionII17 },
      { id: 18, pic: AnglesClassIIDivisionII18 },
      { id: 19, pic: AnglesClassIIDivisionII19 },
      { id: 20, pic: AnglesClassIIDivisionII20 },
    ],
  },
  {
    id: 16,
    pic: classification16,
    title: _t("Сужение зубных дуг в области премоляров"),
    value: "EXPANSION",
    necData: [
      {
        pic: uploadUpper,
        type: "upper_jaw_stl",
        title: _t("Загрузить верхнюю челюсть"),
      },
      {
        pic: uploadLower,
        type: "lower_jaw_stl",
        title: _t("Загрузить нижнюю челюсть"),
      },
      {
        pic: panoram,
        type: "panoramic",
        title: _t("Панорамный снимок"),
      },
      {
        pic: cephal,
        type: "cephalometric",
        title: _t("ТРГ боковой проекции"),
      },
      {
        pic: cbct,
        type: "cbct",
        title: _t("КТ"),
      },
    ],
    link: "expansion-of-dental-arches-in-the-area-of-premolars",
    slides: [
      { id: 1, pic: Expansionofdentalarches1 },
      { id: 2, pic: Expansionofdentalarches2 },
      { id: 3, pic: Expansionofdentalarches3 },
      { id: 4, pic: Expansionofdentalarches4 },
      { id: 5, pic: Expansionofdentalarches5 },
      { id: 6, pic: Expansionofdentalarches6 },
      { id: 7, pic: Expansionofdentalarches7 },
      { id: 8, pic: Expansionofdentalarches8 },
      { id: 9, pic: Expansionofdentalarches9 },
      { id: 10, pic: Expansionofdentalarches10 },
      { id: 11, pic: Expansionofdentalarches11 },
      { id: 12, pic: Expansionofdentalarches12 },
      { id: 13, pic: Expansionofdentalarches13 },
      { id: 14, pic: Expansionofdentalarches14 },
      { id: 15, pic: Expansionofdentalarches15 },
    ],
    clarification: [_t("Не II класс по Энгелю."), _t("Без изменения соотношения жевательных зубов")],
  },
  {
    id: 17,
    pic: classification17,
    title: _t("Скученность зубов на обе челюсти"),
    value: "CROWDING_BOTH",
    necData: [
      {
        pic: uploadUpper,
        type: "upper_jaw_stl",
        title: _t("Загрузить верхнюю челюсть"),
      },
      {
        pic: uploadLower,
        type: "lower_jaw_stl",
        title: _t("Загрузить нижнюю челюсть"),
      },
      {
        pic: panoram,
        type: "panoramic",
        title: _t("Панорамный снимок"),
      },
      {
        pic: cephal,
        type: "cephalometric",
        title: _t("ТРГ боковой проекции"),
      },
      {
        pic: cbct,
        type: "cbct",
        title: _t("КТ"),
      },
      {
        pic: proto,
        type: "photo_protocol",
        title: _t("Фото Протокол"),
      },
    ],
    link: "crowding-of-teeth-on-both-jaws",
    slides: [
      { id: 1, pic: Crowdingofteethonbothjaws1 },
      { id: 2, pic: Crowdingofteethonbothjaws2 },
      { id: 3, pic: Crowdingofteethonbothjaws3 },
      { id: 4, pic: Crowdingofteethonbothjaws4 },
      { id: 5, pic: Crowdingofteethonbothjaws5 },
      { id: 6, pic: Crowdingofteethonbothjaws6 },
      { id: 7, pic: Crowdingofteethonbothjaws7 },
      { id: 8, pic: Crowdingofteethonbothjaws8 },
      { id: 9, pic: Crowdingofteethonbothjaws9 },
      { id: 10, pic: Crowdingofteethonbothjaws10 },
      { id: 11, pic: Crowdingofteethonbothjaws11 },
      { id: 12, pic: Crowdingofteethonbothjaws12 },
      { id: 13, pic: Crowdingofteethonbothjaws13 },
      { id: 14, pic: Crowdingofteethonbothjaws14 },
      { id: 15, pic: Crowdingofteethonbothjaws15 },
    ],
    clarification: [_t("Не II класс по Энгелю"), _t("Не III класс по Энгелю")],
  },
  {
    id: 18,
    pic: classification18,
    title: _t("Overjet (сагиттальная щель)"),
    value: "OVERJET",
    necData: [
      {
        pic: uploadUpper,
        type: "upper_jaw_stl",
        title: _t("Загрузить верхнюю челюсть"),
      },
      {
        pic: uploadLower,
        type: "lower_jaw_stl",
        title: _t("Загрузить нижнюю челюсть"),
      },
      {
        pic: panoram,
        type: "panoramic",
        title: _t("Панорамный снимок"),
      },
      {
        pic: cephal,
        type: "cephalometric",
        title: _t("ТРГ боковой проекции"),
      },
      {
        pic: cbct,
        type: "cbct",
        title: _t("КТ"),
      },
    ],
    link: "overjet",
    slides: [
      { id: 1, pic: Overjetsagittalfissure1 },
      { id: 2, pic: Overjetsagittalfissure2 },
      { id: 3, pic: Overjetsagittalfissure3 },
      { id: 4, pic: Overjetsagittalfissure4 },
      { id: 5, pic: Overjetsagittalfissure5 },
      { id: 6, pic: Overjetsagittalfissure6 },
      { id: 7, pic: Overjetsagittalfissure7 },
      { id: 8, pic: Overjetsagittalfissure8 },
      { id: 9, pic: Overjetsagittalfissure9 },
      { id: 10, pic: Overjetsagittalfissure10 },
      { id: 11, pic: Overjetsagittalfissure11 },
      { id: 12, pic: Overjetsagittalfissure12 },
      { id: 13, pic: Overjetsagittalfissure13 },
      { id: 14, pic: Overjetsagittalfissure14 },
      { id: 15, pic: Overjetsagittalfissure15 },
    ],
    clarification: [_t("Без изменения соотношения жевательных зубов")],
  },
  {
    id: 19,
    pic: classification19,
    title: _t("Overbite (глубокий прикус)"),
    value: "OVERBITE",
    necData: [
      {
        pic: uploadUpper,
        type: "upper_jaw_stl",
        title: _t("Загрузить верхнюю челюсть"),
      },
      {
        pic: uploadLower,
        type: "lower_jaw_stl",
        title: _t("Загрузить нижнюю челюсть"),
      },
      {
        pic: panoram,
        type: "panoramic",
        title: _t("Панорамный снимок"),
      },
      {
        pic: cephal,
        type: "cephalometric",
        title: _t("ТРГ боковой проекции"),
      },
      {
        pic: cbct,
        type: "cbct",
        title: _t("КТ"),
      },
    ],
    link: "overbite",
    slides: [
      { id: 1, pic: Overbitedeepbite1 },
      { id: 2, pic: Overbitedeepbite2 },
      { id: 3, pic: Overbitedeepbite3 },
      { id: 4, pic: Overbitedeepbite4 },
      { id: 5, pic: Overbitedeepbite5 },
      { id: 6, pic: Overbitedeepbite6 },
      { id: 7, pic: Overbitedeepbite7 },
      { id: 8, pic: Overbitedeepbite8 },
      { id: 9, pic: Overbitedeepbite9 },
      { id: 10, pic: Overbitedeepbite10 },
      { id: 11, pic: Overbitedeepbite11 },
      { id: 12, pic: Overbitedeepbite12 },
      { id: 13, pic: Overbitedeepbite13 },
      { id: 14, pic: Overbitedeepbite14 },
    ],
    clarification: [_t("Не II класс по Энгелю"), _t("Без изменения соотношения жевательных зубов")],
  },
  {
    id: 20,
    pic: classification20,
    title: _t("Открытый прикус"),
    value: "OPENBITE",
    necData: [
      {
        pic: uploadUpper,
        type: "upper_jaw_stl",
        title: _t("Загрузить верхнюю челюсть"),
      },
      {
        pic: uploadLower,
        type: "lower_jaw_stl",
        title: _t("Загрузить нижнюю челюсть"),
      },
      {
        pic: panoram,
        type: "panoramic",
        title: _t("Панорамный снимок"),
      },
      {
        pic: cephal,
        type: "cephalometric",
        title: _t("ТРГ боковой проекции"),
      },
      {
        pic: cbct,
        type: "cbct",
        title: _t("КТ"),
      },
    ],
    link: "openbite",
    slides: [
      { id: 1, pic: Openbite1 },
      { id: 2, pic: Openbite2 },
      { id: 3, pic: Openbite3 },
      { id: 4, pic: Openbite4 },
      { id: 5, pic: Openbite5 },
      { id: 6, pic: Openbite6 },
      { id: 7, pic: Openbite7 },
      { id: 8, pic: Openbite8 },
      { id: 9, pic: Openbite9 },
      { id: 10, pic: Openbite10 },
      { id: 11, pic: Openbite11 },
      { id: 12, pic: Openbite12 },
      { id: 13, pic: Openbite13 },
      { id: 14, pic: Openbite14 },
      { id: 15, pic: Openbite15 },
    ],
    clarification: [_t("Не III класс по Энгелю"), _t("Без изменения соотношения жевательных зубов")],
  },
];
