import { Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import "./Footer.scss";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toggleDialog } from "../../actions/dialogsAction";
import { ROUTES } from "../../constants/routes";

export const Footer = () => {
  const { t: _t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <footer className="footer">
      <div className="container">
        <Link to={ROUTES.home} className="footer__logo">
          TranspaDent <br /> {new Date().getFullYear()}
        </Link>
        <nav className="footer__nav">
          <Button className="footer__link" onClick={() => dispatch(toggleDialog("contacts", true))}>
            {_t("Контакты")}
          </Button>
          <Button component={Link} className="footer__link" to={ROUTES.forDoctors}>
            {_t("Я врач")}
          </Button>
        </nav>
      </div>
    </footer>
  );
};
