import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./ClinicalProtocols.scss";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import ClinicalProtocolsDialog from "./Dialog";
import UsingElastics from "./DialogsContent/1_using-elastics";
import FixingAttachments from "./DialogsContent/2_fixing-attachments";
import InterproximalReduction from "./DialogsContent/3_interproximal-reduction";
import PhotoProtocol from "./DialogsContent/4_photo-protocol";
import RestorationProtocol from "./DialogsContent/5_restoration-protocol";
import Recommendations from "./DialogsContent/6_recommendations";

export const ClinicalProtocols = () => {
  const { t: _t } = useTranslation();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState(null);

  const cards = [
    {
      id: 1,
      cardImg: require("./assets/1_using-elastics/card_bg.jpg"),
      cardTitle: _t("Протоколы использования эластиков"),
      cardContent: <UsingElastics />,
      hash: "#using-elastics",
    },
    {
      id: 2,
      cardImg: require("./assets/2_fixing-attachments/card_bg.jpg"),
      cardTitle: _t("Фиксация аттачментов"),
      cardContent: <FixingAttachments />,
      hash: "#fixing-attachments",
    },
    {
      id: 3,
      cardImg: require("./assets/3_interproximal-reduction/card_bg.jpg"),
      cardTitle: _t("Межапроксимальная редукция"),
      cardContent: <InterproximalReduction />,
      hash: "#interproximal-reduction",
    },
    {
      id: 4,
      cardImg: require("./assets/4_photo-protocol/card_bg.jpg"),
      cardTitle: _t("Фото протокол"),
      cardContent: <PhotoProtocol />,
      hash: "#photo-protocol",
    },
    {
      id: 5,
      cardImg: require("./assets/5_restoration-protocol/card_bg.jpg"),
      cardTitle: _t("Протокол реставрации окклюзионных поверхностей"),
      cardContent: <RestorationProtocol />,
      hash: "#restoration-protocol",
    },
    {
      id: 6,
      cardImg: require("./assets/6_recommendations/card_bg.jpg"),
      cardTitle: _t("Рекомендации пациентам по использованию элайнеров"),
      cardContent: <Recommendations />,
      hash: "#recommendations",
    },
  ];

  const { push } = useHistory();
  const { hash } = useLocation();

  const toggle = (hash) => push({ hash });

  useEffect(() => {
    if (hash) {
      const currentProtocol = cards.find((el) => el.hash === hash);
      setTitle(currentProtocol.cardTitle);
      setContent(currentProtocol.cardContent);
    }
  }, [hash]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ClinicalProtocolsDialog opened={!!hash} toggle={toggle} title={title} content={content} />
      <div className="clinical-protocols">
        {cards.map(({ id, cardImg, cardTitle, hash }) => (
          <div className="clinical-protocols__card" key={id}>
            <div className="clinical-protocols__img">
              <div className="clinical-protocols__overlay" />
              <img src={cardImg} alt="" />
              <Button variant="contained" color="primary" onClick={() => toggle(hash)}>
                {_t("Просмотреть")}
              </Button>
            </div>
            <div className="clinical-protocols__title">{cardTitle}</div>
          </div>
        ))}
      </div>
    </>
  );
};
